import { useContext } from "react";
import ContactForm from "../Components/ContactForm";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Newsletter from "../Components/Newsletter";
import { DarkModeContext } from "../DarkModeContext";
import { useMediaQuery } from "@mui/material";
import { primaryDarkColor } from "../../constant";

export default function Contact() {

    const { darkMode } = useContext(DarkModeContext);
    const isDesktop = useMediaQuery('(min-width: 768px)');

    return (
        <>
            <Header />
            <ContactForm />
            <Newsletter />
            <div style={{ background: darkMode ? primaryDarkColor : '', paddingTop: isDesktop ? '' : '10%' }}><Footer /></div>
        </>
    )
}