import { useContext } from "react";
import Footer from "../Components/Footer";
import FreshMilk from "../Components/FreshMilk";
import GetFreshMilk from "../Components/GetFreshMilk";
import Header from "../Components/Header";
import HeroSection from "../Components/HeroSection";
import Newsletter from "../Components/Newsletter";
import Testimonials from "../Components/Testimonials";
import WhyChooseUs from "../Components/WhyChooseUs";
import { DarkModeContext } from "../DarkModeContext";
import { primaryDarkColor } from "../../constant";

export default function Home() {

    const { darkMode } = useContext(DarkModeContext);

    return (
        <>
            <Header />
            <HeroSection />
            <FreshMilk />
            <GetFreshMilk />
            <WhyChooseUs />
            <div style={{ padding: '3% 0', background: darkMode ? primaryDarkColor : '' }}><Testimonials /></div>
            <Newsletter />
            <Footer />
        </>
    )
}