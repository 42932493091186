import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { primaryColor, primaryDarkColor } from '../../constant';
import { DarkModeContext } from '../DarkModeContext';

const GetFreshMilk = () => {

    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const { darkMode } = useContext(DarkModeContext);
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isMediumScreen || isMediumScreen ? '20px' : '',
            backgroundColor: primaryDarkColor,
            color: 'white',
            position: 'relative',
            overflow: 'hidden',
        },
        textContainer: {
            flex: 1,
            maxWidth: '50%',
            position: 'relative',
            zIndex: 99,
            textAlign: isSmallScreen ? 'center' : 'left',
        },
        leftText: {
            fontSize: isSmallScreen ? '16px' : '24px',
            color: 'white',
            top: '10%',
            position: isSmallScreen ? '' : 'absolute',
            lineHeight: '1.8',
            zIndex: 99,
            marginTop: isSmallScreen ? '6%' : '',
            marginLeft: isSmallScreen ? '6%' : '10%',
            textAlign: isSmallScreen ? 'left' : '',
        },
        rightText: {
            fontSize: isSmallScreen ? '16px' : '24px',
            color: primaryColor,
            lineHeight: '1.6',
            top: '5%',
            zIndex: 99,
            width: isSmallScreen ? '100%' : '70%',
            position: 'absolute',
            right: '2%',
            left: isSmallScreen ? '6%' : '',
            lineHeight: '1.5',
        },
        imageContainer: {
            flex: 1,
            position: 'relative',
            display: 'flex',
            zIndex: 99,
            justifyContent: 'center',
            alignItems: 'center',
        },
        image: {
            width: 300,
            // height: 600,
            // borderRadius: '10px',
        },
    };

    return (
        <div style={styles.container}>
            {/* {
                darkMode && (
                    <div className='blurDiv2' style={{ zIndex: 2 }}></div>
                )
            } */}
            <div style={styles.textContainer}>
                <div style={styles.leftText}>
                    By choosing MilkPromise, you are not only getting the best milk but also contributing to the
                    livelihood of local farmers. Our seamless process ensures that you get the freshest milk while helping
                    the community thrive.
                </div>
            </div>
            <div style={styles.imageContainer}>
                {/* <div style={styles.rightText}>
                    Join Us in Supporting Local Farmers
                </div> */}
                <img src="/images/get-milk-img.png" alt="Get Milk" style={styles.image} />
            </div>
        </div>
    );
};

export default GetFreshMilk;