import AboutSection from "../Components/AboutSection";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Newsletter from "../Components/Newsletter";
import FAQSection from "../Components/Faq";
import WhyChooseSection from "../Components/WhyChoose";
import SaveEnvironmentSection from "../Components/SaveEnvironment";
import FarmtoDoorSection from "../Components/FarmtoDoor";

export default function About() {
    return (
        <>
            <Header />
            <AboutSection />
            <SaveEnvironmentSection />
            <WhyChooseSection />
            <FarmtoDoorSection />
            <FAQSection />
            <Newsletter />
            <Footer />
        </>
    )
}