import { useContext, useState } from "react";
import BenefitsHeroSection from "../Components/BenefitsHeroSection";
import BenefitsSection from "../Components/BenefitsSection";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import KnowYourMilk from "../Components/KnowYourMilk";
import NutritionTable from "../Components/NutritionTable";
import RecipesSection from "../Components/RecipesSection";
import SubscriptionForm from "../Components/SubscriptionForm";
import CowMilkSection from "../Components/CowMilkSection"
import { DarkModeContext } from "../DarkModeContext";
import { primaryDarkColor } from "../../constant";

export default function MilkPage() {

    const { darkMode } = useContext(DarkModeContext);
  

    return (
        <>
            <Header />
            <BenefitsHeroSection />
            <CowMilkSection />
            <div style={{ padding: '5% 0 0', background: darkMode ? primaryDarkColor : '' }}><BenefitsSection /></div>
            <div style={{ padding: '3% 0 0', background: darkMode ? primaryDarkColor : '' }}><NutritionTable /></div>
            <div style={{ padding: '8% 0', background: darkMode ? primaryDarkColor : '' }}><RecipesSection /></div>
            <KnowYourMilk />
            <Footer />
        </>
    )
}